<script setup lang="ts">
import { PersonalApi } from '~/net/apis/personal_api'
import { useDayjs } from '#dayjs'

const onClickLeft = () => history.back()
const userStore = useUser()
const num = ref(0)
const list = ref<Array<ShareRecordQuery>>([])
async function getShareInfo() {
  const { data } = await PersonalApi.shareInfo()
  const { data: _list } = await PersonalApi.queryShareRecordList({ inviterId: userStore.userInfo.id })
  num.value = data.shareTotal ?? 0
  list.value = _list
}
getShareInfo()

function vipReceiveStatus(status: number): string {
  let StatusName = ''
  switch (status) {
    case 1:
      StatusName = '已自动领取'
      break
    default:
      StatusName = '未领取'
      break
  }
  return StatusName
}

function vipStatus(vipCardCreatedAt: string, vipCardExpireAt?: string, i?: number): string {
  let StatusName = ''
  const dayjs = useDayjs()
  const m = dayjs(vipCardCreatedAt).unix()
  if (dayjs().unix() < m) {
    StatusName = '已使用'
  } else {
    StatusName = '未使用'
  }
  return StatusName
}

function formatTime(times:string): string {
  return useDayjs()(times).format('YYYY-MM-DD HH:mm:ss')
}
</script>

<template>
  <div h-screen>
    <van-nav-bar title="分享推广记录" left-arrow @click-left="onClickLeft" />
    <div m-4 text-xs ct-FFFFFF>
      我的累积分享人数：<span ct-FD9403>{{ num }}</span>
    </div>
    <van-row m-4 mb-0 mt-0 h-8.5 text-center text-base leading-8.5 bg-414660 ct-e8e8e8>
      <van-col span="6"> 用户名 </van-col>
      <van-col span="6"> 领取时间 </van-col>
      <van-col span="6"> VIP卡 </van-col>
      <van-col span="6"> 状态 </van-col>
    </van-row>
    <div v-if="list.length > 0">
      <van-row
        v-for="(item, index) in list"
        :key="index"
        m-4
        mb-0
        mt-0
        h-6.5
        text-center
        text-xs
        leading-6.5
        bg-414660
        ct-ffffff
      >
        <van-col border-1 border-gray-600 span="6">
          {{ item.username }}
        </van-col>
        <van-col border-1 border-gray-600 span="6">
          {{ formatTime(item.vipCardCreatedAt) }}
        </van-col>
        <van-col border-1 border-gray-600 span="6">
          {{ vipReceiveStatus(item.vipCardReceiveStatus) }}
        </van-col>
        <van-col border-1 border-gray-600 :class="vipStatus(item.vipCardCreatedAt) === '已使用' ? 'ct-6A6C77' : 'ct-ffffff'" span="6">
          {{ vipStatus(item.vipCardCreatedAt) }}
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<style scoped>
:deep(.van-nav-bar__content) {
  background: #111425;
}
:deep(.van-nav-bar__title) {
  color: #d0d0d0;
}
:deep(.van-nav-bar .van-icon) {
  color: #ffffff;
}
:deep(.van-hairline--bottom:after) {
  border-bottom-width: 0;
}
</style>
